import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Cleaners =  () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/pool-resurfacing.jpg" className="top">
      <h1>Pool Cleaners</h1>
    </BgImage>
    <section className="content">
      <p>
        Pool cleaning and maintenance is a task that can be burdensome, but is
        essential for keeping your pool clean and safe all year round. We know
        what its like to need to keep a pool clean, and can set you up with the
        best equipment to make sure your pool stays clean and stress free, so
        you can focus on enjoying it. Speak to us about:
      </p>
      <ul>
        <li>Suction Cleaners</li>
        <li>Pressure Cleaners</li>
        <li>Robotic Cleaners</li>
        <li>Handover Equipment</li>
      </ul>
    </section>
  </Layout>
)

export default Cleaners
